// app/lib/auth.ts

export function login(username: string, password: string): boolean {
  const CORRECT_USERNAME = 'admin';
  const CORRECT_PASSWORD = 'password123';

  if (username === CORRECT_USERNAME && password === CORRECT_PASSWORD) {
    if (typeof window !== 'undefined') {
      document.cookie = 'isLoggedIn=true; path=/; max-age=3600';
    }
    return true;
  }
  return false;
}

export function logout(): void {
  if (typeof window !== 'undefined') {
    document.cookie = 'isLoggedIn=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}

export function isAuthenticated(): boolean {
  if (typeof window !== 'undefined') {
    return document.cookie.includes('isLoggedIn=true');
  }
  return false;
}